<template>
  <div class="vote-checker">
    <div class="aligned-div">
      <el-form
        ref="ruleFormRef"
        style="max-width: 600px"
        :model="ruleForm"
        label-width="auto"
        size="default"
        status-icon
      >
        <el-form-item label="Token de Votación" prop="token">
          <el-input v-model="ruleForm.vote_token" style="width: 25vw" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">
            Buscar
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="aligned-div-2" v-if="vote_info">
      <p>Token: {{ vote_info.id }}</p>
      <p>Fecha del Voto: {{ date }}</p>
      <p>Votación: {{ vote_info.name }} (id={{ vote_info.voting_id }})</p>
      <p>Usuario: {{ vote_info.user }}</p>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import axios from "axios";

const ruleFormRef = ref();
const ruleForm = reactive({
  vote_token: "",
});

const vote_info = ref();
let date = ref();
const submitForm = async () => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BACK_URL}/api/v1/vote/check?id=${ruleForm.vote_token}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken()}`,
        },
      }
    );
    response.data.vote;
    vote_info.value = response.data;
    date.value = new Date(response.data.datetime);
    date.value = date.value.toLocaleDateString("sv-SE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.setItem("accessToken", "null");
    }
  }
};

function accessToken() {
  return localStorage.getItem("accessToken");
}
</script>

<style scoped>
.vote-checker {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5vw;
}

.aligned-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; /* Espacio entre los divs hijos */
}

.aligned-div-2 {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; /* Espacio entre los divs hijos */
}

.contenido {
  /* Puedes ajustar el ancho máximo según tus necesidades */
  max-width: 80%;
}

p {
  font-weight: bold;
}
</style>
